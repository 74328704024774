import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retryWhen, skipWhile, switchMap } from 'rxjs/operators';
import { User } from '@xpo-ltl/sdk-common';
import { AdminPersonnel, PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { UserService } from './user.service';
import { PricingCodesService } from './pricing-codes.service';
import { PricingWorkbenchService } from './pricing-workbench.service';
import { PricingCodesCategories, PricingCodesSubCategories } from '../enums/pricing-codes.enum';
import { UserPermission } from '../enums/user-permission.enum';
import { genericRetryStrategy } from '../shared/utils/rxjs-utils';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  userPermissions = new BehaviorSubject<Array<string>>(undefined);
  userPermissions$ = this.userPermissions.asObservable();

  constructor(
    private userService: UserService,
    private pricingCodes: PricingCodesService,
    private pwbService: PricingWorkbenchService
  ) {
    this.userService.user$.pipe(skipWhile((user) => !user)).subscribe((user: User) => {
      this.loadPermissions();
    });
  }

  loadPermissions() {
    this.getPermissions().subscribe((permissions) => {
      this.userPermissions.next(permissions);
    });
  }

  getPermissions(): Observable<Array<string>> {
    return this.pwbService.getLoggedInAnalyst().pipe(
      switchMap((personnel: AdminPersonnel) => {
        if (personnel.activeInd) {
          const employeeRoleType = personnel.employeeRoleType ? personnel.employeeRoleType.trim() : '';
          const additionalRoleType = personnel.additionalRoleType ? personnel.additionalRoleType.trim() : '';
          const roles = [];
          if (employeeRoleType && !roles.includes(employeeRoleType)) {
            roles.push(employeeRoleType);
          }
          if (additionalRoleType) {
            additionalRoleType.split(',').forEach((additionalRole) => {
              const role = additionalRole ? additionalRole.trim() : '';
              if (role && !roles.includes(role)) {
                roles.push(role);
              }
            });
          }
          return this.pricingCodes.getPricingCodes().pipe(
            switchMap((response: PricingCode[]) => {
              const permissions = _.filter(response, (permission) => {
                return (
                  permission.category === PricingCodesCategories.agreementManagement &&
                  permission.subCategory === PricingCodesSubCategories.security &&
                  roles.includes(permission.code)
                );
              }).map((permission: PricingCode) => permission.name);
              if (personnel) {
                permissions.push(UserPermission.RulesetDetail);
              }
              const result = [...new Set(permissions)];
              return of(result);
            })
          );
        } else {
          return of([]);
        }
      }),
      retryWhen(
        genericRetryStrategy({
          scalingDuration: 2000,
          excludedStatusCodes: [404],
        })
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  hasPermission(permission: string): Observable<boolean> {
    if (this.userPermissions.value) {
      return of(this.userPermissions.value.includes(permission));
    } else {
      return this.getPermissions().pipe(
        map((data) => {
          return data.includes(permission);
        })
      );
    }
  }
}
