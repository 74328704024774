import { AfterViewChecked, Component, ElementRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

export enum ValidationKeys {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
}

export interface INumberCellEditorParams extends ICellEditorParams {
  eventKey: string;
  /** Min allowed value. */
  min?: number;
  /** Max allowed value. */
  max?: number;
  /** Number of digits allowed after the decimal point. */
  precision?: number;
  /**
   * Size of the value change when stepping up/down, starting from `min` or the initial value if provided.
   * Step is also the difference between valid values.
   * If the user-provided value isn't a multiple of the step value from the starting value, it will be considered invalid.
   * Defaults to any value allowed.
   */
  step?: number;
  /**
   * Display stepper buttons in editor. Note: Does not work when `preventStepping` is `true`.
   * @default false
   */
  showStepperButtons?: boolean;
  /**
   * Set to `true` to prevent key up/down from stepping the field's value.
   * @default false
   */
}

@Component({
  selector: 'xpo-numeric-cell-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--numeric',
  },
})
export class NumericEditorComponent implements ICellEditorAngularComp {
  @ViewChild('input', { read: ViewContainerRef }) public input!: ViewContainerRef;
  private params: INumberCellEditorParams;
  value;
  min: number;
  max: number;
  step: number;
  private focusAfterAttached: boolean;

  agInit(params: INumberCellEditorParams): void {
    this.focusAfterAttached = params.cellStartedEdit;
    this.params = params;
    this.value = this.isCharNumeric(params.eventKey) ? params.eventKey : params.value;
    this.min = params.min;
    this.max = params.max;
    this.step = params.step;
  }

  getValue(): number | null {
    const value = this.value;
    return value === '' || value == null ? null : parseFloat(value);
  }

  onKeyDown(event: any): void {
    if (!event.key || event.key.length !== 1 || this.isNumericKey(event)) {
      return;
    }
    this.input.element.nativeElement.focus();

    if (event.preventDefault) event.preventDefault();
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this.focusAfterAttached) {
        this.input.element.nativeElement.focus();
        this.input.element.nativeElement.select();
      }
    });
  }

  // when we tab into this editor, we want to focus the contents
  focusIn() {
    this.input.element.nativeElement.focus();
    this.input.element.nativeElement.select();
  }

  // when we tab out of the editor, this gets called
  focusOut() {}

  private isCharNumeric(charStr: string | null): boolean {
    return charStr != null && !!/\d|\./.test(charStr);
  }

  private isNumericKey(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }
}
