import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';

export interface IconButtonCellRendererParams extends ICellRendererParams {
  onClickCallback: (node: RowNode) => void;
  disabledCallback?: (node: RowNode) => boolean;
  visibleCallback?: (node: RowNode) => boolean;
  iconNameCallback?: (node: RowNode) => string;
  iconName: string;
  tooltip: string;
}

@Component({
  selector: 'icon-button-cell-renderer',
  templateUrl: './icon-button-cell-renderer.component.html',
  styleUrls: ['./icon-button-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonCellRendererComponent {
  @Input() params!: IconButtonCellRendererParams;
  disabled: boolean = false;
  visible: boolean = false;
  iconName: string = '';

  constructor(public confirmDialog: XpoConfirmDialog) {}

  agInit(params: IconButtonCellRendererParams): void {
    this.params = params;
    this.updateDisabledState();
    this.updateVisibleState();
    this.updateIconName();
  }

  onClick($event: MouseEvent) {
    if (this.params.onClickCallback instanceof Function) {
      if (this.iconName === 'delete') {
        const confirmConfig: XpoConfirmDialogConfig = {
          confirmButtonText: 'Delete',
          rejectButtonText: 'Cancel',
          icon: 'warning',
          showCancelButton: true,
        };

        this.confirmDialog
          .confirm('', 'Are you sure that you want to delete this record?', confirmConfig)
          .subscribe((response) => {
            if (response) {
              this.params.onClickCallback(this.params.node);
            }
          });
      } else this.params.onClickCallback(this.params.node);
    }
  }

  private updateDisabledState(): void {
    this.disabled = this.params.disabledCallback ? this.params.disabledCallback(this.params.node) : false;
  }

  private updateVisibleState(): void {
    this.visible = this.params.visibleCallback ? this.params.visibleCallback(this.params.node) : true;
  }

  private updateIconName(): void {
    this.iconName = this.params.iconNameCallback
      ? this.params.iconNameCallback(this.params.node)
      : this.params.iconName;
  }
}
