export enum PricingCodesCategories {
  plDocs = 'PlDocs',
  pricingAnalysis = 'PricingAnalysis',
  qrb = 'QRB',
  salesRfp = 'SalesRfp',
  agreementManagement = 'AgreementManagement',
  artemis = 'Artemis',
  dynPricing = 'DynPricing',
  obi = 'OBI',
}

export enum PricingCodesSubCategories {
  recordType = 'RecordType',
  docType = 'DocType',
  security = 'Security',
  nMFCClass = 'NMFCClass',
  displayItems = 'displayItems',
  maintenanceStatusFilter = 'MaintenanceStatusFlt',
  maintenanceTypeFilter = 'MaintenanceTypeFlt',
  maintenanceDefaultFilter = 'MaintenanceDfltFlt',
  tagAuditFlag = 'TagAuditFlag',
  state = 'State',
  stateComponent = 'StateComponent',
}
