import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @Input() tooltips: { [label: string]: string };
  @Input() label: string;
}
