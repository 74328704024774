import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User as UserModel } from '@xpo-ltl/sdk-common';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import UserRoleHelper from '../enums/user-role/user-role-helper';
import { UserRole } from '../enums/user-role/user-role.enum';
import { RoleConfig } from '../models/role-config.model';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  userSource = new BehaviorSubject<UserModel>(undefined);
  user$ = this.userSource.asObservable();

  private _isProduction = false;
  private _roleConfig: RoleConfig;
  private readonly removeDomainRegExp = /.*\//gm;

  get User(): UserModel {
    return this.userSource.value;
  }

  set User(value: UserModel) {
    this.userSource.next(value);
  }

  get isProduction(): boolean {
    return this._isProduction;
  }

  get roleConfig(): RoleConfig {
    return this._roleConfig;
  }

  constructor(private configManagerService: ConfigManagerService) {
    this._isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    /** TODO: establish roles for PROD / NON-PROD */
    this._roleConfig = {
      pricingAdmin: UserRole.pricingAdmin,
      pricingCorr: UserRole.pricingCorr,
      pricingOps: UserRole.pricingOps,
      ramAccessorialMgmtCorr: UserRole.ramAccessorialMgmtCorr,
      ramAccessorialMgmtOps: UserRole.ramAccessorialMgmtOps,
    };
    if (!this._isProduction) {
      this._roleConfig.ramAccessorialMgmtCorrTest = UserRole.ramAccessorialMgmtCorrTest;
      this._roleConfig.ramAccessorialMgmtOpsTest = UserRole.ramAccessorialMgmtOpsTest;
    }
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const splitUserRolesArr = _.map(userRoles, (role: string) => {
      return role.replace(this.removeDomainRegExp, '');
    });
    const results = _.intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results && !!results.length;
  }

  /** TODO: an authorized user can have any set of roles defined above... */
  isAuthorizedUser(user: UserModel): boolean {
    return this.hasRole(user.roles, this._roleConfig[UserRole.pricingAdmin]);
  }

  /** TODO: some authorized users may have mutliple roles... see role-check-guard.ts as it handles this!!! */
  hasMultipleRoles(user: UserModel): boolean {
    return this.hasRole(user.roles, this._roleConfig[UserRole.pricingAdmin]);
  }

  setRole(role: UserRole) {
    if (!!this.User && !this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production)) {
      const roleEnum = UserRoleHelper.toEnum(role);
      if (!!roleEnum) {
        this.User.roles.length = 0;
        _.castArray(this._roleConfig[roleEnum]).forEach((roleName) => this.User.roles.push(roleName));
      }
    }
  }

  getRole(): UserRole {
    if (this.hasRole(this.User.roles, this._roleConfig[UserRole.pricingAdmin])) {
      return UserRole.pricingAdmin;
    }
  }
}
